// import { Link } from "react-router-dom";
// import { useState } from "react";
// const Header=()=>{

//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//       setIsMenuOpen(!isMenuOpen);
//   };  
//     return(
//         <>    
//        <div className="topbar wow fadeInUp">
//          <div className="container">
//            <div className="row">
//              <div className="col-md-8">
//                <div className="topbar-contact-info">
//                  <ul>
//                    <li>
//                       <Link to="https://mail.google.com/mail/?view=cm&fs=1&to=sunsparkleenergy@gmail.com" target="_blank">
//                        <i className="fa-solid fa-envelope"></i>sunsparkleenergy.com
//                      </Link>
//                    </li>
//                    <li>
//                      <Link to="tel:+91 7069517569">
//                        <i className="fa-solid fa-phone"></i> +91 70695 17569
//                      </Link>
//                    </li>
//                  </ul>
//                </div>
//              </div>

//              <div className="col-md-4">
//                <div className="header-social-links">
//                  <ul>
//                    <li>
//                     <Link to="https://x.com/sunsparklenergy">
//                        <i className="fa-brands fa-twitter"></i>
//                      </Link>
//                    </li>
//                    <li>
//                      <Link to="https://www.linkedin.com/in/umang-keraliya-8857a2274/">
//                        <i className="fa-brands fa-linkedin-in"></i>
//                      </Link>
//                    </li>
//                    <li>
//                      <Link to="https://www.instagram.com/sunsparkle.energy/">
//                        <i className="fa-brands fa-instagram"></i>
//                      </Link>
//                    </li>
//                  </ul>
//                </div>
//              </div>
//            </div>
//          </div>
//        </div>

//        <header className="main-header">
//          <div className="header-sticky">
//            <nav className="navbar navbar-expand-lg">
//              <div className="container">
//                <Link className="navbar-brand" to="/">
//                  <img src="images/logo.svg" alt="Logo" />
//                </Link>

//                <div className="collapse navbar-collapse main-menu">
//                  <ul className="navbar-nav mr-auto" id="menu">
//                    <li className="nav-item">
//                      <Link to="/" className="nav-link">
//                        Home
//                      </Link>
//                    </li>
//                    <li className="nav-item">
//                    <Link to="/about" className="nav-link">
//                        About Us
//                      </Link>
//                    </li>
//                    <li className="nav-item">
//                    <Link to="/services" className="nav-link">
//                        Services
//                      </Link>
//                    </li> 
//                    <li className="nav-item">
//                    <Link to="/contact" className="nav-link">
//                        Contact Us
//                      </Link>
//                    </li>
//                    <li className="nav-item highlighted-menu">
//                    <Link to="/contact" className="nav-link">
//                        Book Now
//                      </Link>
//                    </li>
//                  </ul>
//                </div>

//                <div className="navbar-toggle"  onClick={toggleMenu}>

//                <i className="fa fa-bars"></i>
//                </div>
//              </div>
//            </nav>

//            <div className={`responsive-menu ${isMenuOpen ? 'open' : ''}`}>
//            <ul className="navbar-nav">
//                             <li className="nav-item">
//                                 <Link to="/" className="nav-link" onClick={toggleMenu}>
//                                     Home
//                                 </Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to="/about" className="nav-link" onClick={toggleMenu}>
//                                     About Us
//                                 </Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to="/services" className="nav-link" onClick={toggleMenu}>
//                                     Services
//                                 </Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to="/contact" className="nav-link" onClick={toggleMenu}>
//                                     Contact Us
//                                 </Link>
//                             </li>
//                         </ul>

//            </div>
//          </div>
//        </header>
//         </>
//     );
// }
// export default Header;






import { Link } from "react-router-dom";
import { useState } from "react";
//import './Header.css'; // Import your CSS file for styling

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="topbar wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="topbar-contact-info">
                <ul>
                  <li>
                    <Link to="mailto:sunsparkleenergy@gmail.com">
                      <i className="fa-solid fa-envelope"></i> sunsparkleenergy@gmail.com
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:+917069517569">
                      <i className="fa-solid fa-phone"></i> +91 70695 17569
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="header-social-links">
                <ul>
                  <li>
                    <Link to="https://twitter.com/sunsparklenergy" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/in/umang-keraliya-8857a2274/" target="_blank">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/sunsparkle.energy/" target="_blank">
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="main-header">
        <div className="header-sticky">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="images/mainlogo.png" width="100px" alt="Logo" />
              </Link>

              <div className="collapse navbar-collapse main-menu">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services" className="nav-link">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item highlighted-menu">
                    <Link to="/contact" className="nav-link">
                      Book Now
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="navbar-toggle" onClick={toggleMenu}>
                <i className={`fa fa-bars ${isMenuOpen ? "open" : ""}`}></i>
              </div>
            </div>
          </nav>

          <div className={`responsive-menu ${isMenuOpen ? "open" : ""}`}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={toggleMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" onClick={toggleMenu}>
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link" onClick={toggleMenu}>
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link" onClick={toggleMenu}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
