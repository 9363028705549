import { Link } from "react-router-dom";

const Faq=()=>{
    return(
        <>    
        <div className="page-header parallaxie">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-header-box">
						<h1 className="text-anime">FAQs</h1>
						<nav className="wow fadeInUp" data-wow-delay="0.25s">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
								<li className="breadcrumb-item active" aria-current="page">FAQs</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>

    

    <div class="page-faqs">
		<div class="container">
			<div class="row">
				<div class="col-md-10 offset-md-1">
					<div class="faq-accordion">
						<div class="accordion" id="faq_accordion">
							<div class="accordion-item">
								<h2 class="accordion-header" id="heading1"><button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">What is solar energy?</button></h2>

								<div id="collapse1" class="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#faq_accordion">
									<div class="accordion-body">
										<p>Solar energy is the radiant energy emitted by the sun.
                                         Solar panels convert this energy into electricity through a process called the photovoltaic effect.</p>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="heading2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">Is solar energy right for my home?</button></h2>

								<div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#faq_accordion">
									<div class="accordion-body">
										<p>Several factors influence solar suitability, including your roof size, sun exposure, electricity usage, and local incentives.
                                        We offer free consultations to help you determine if solar is a good fit for you.</p>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="heading3"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="true" aria-controls="collapse3">How much does a solar system cost?</button></h2>

								<div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#faq_accordion">
									<div class="accordion-body">
										<p>The cost of a solar system varies based on factors like system size, equipment chosen, and local regulations. We provide custom quotes based on your specific needs.</p>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="heading4"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">Can solar panels power my entire home?</button></h2>

								<div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#faq_accordion">
									<div class="accordion-body">
										<p>Yes, a properly sized solar system can generate enough electricity to meet your entire household's needs. In some cases, additional electrical components may be required.</p>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="heading5"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">How much does solar energy save on electricity bills?</button></h2>

								<div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#faq_accordion">
									<div class="accordion-body">
										<p>Solar energy can significantly reduce your electricity bills. The amount saved depends on your system size, electricity usage, and local energy rates.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

      

        </>
    );
}
export default Faq;