import { Link } from "react-router-dom";

const Home=()=>{
        return(
            <>    
                    <div className="hero parallaxie">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="hero-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">Welcome to Sunsparkle</h3>
                  <h1 className="text-anime">
                    Powering the Future With <span>Renewable.</span>
                  </h1>
                </div>
                <div
                  className="hero-content-body wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <p>
                  Power your home with clean, renewable energy.
                  Save money on your electricity bills and reduce your carbon footprint.
                  </p>
                </div>

                <div
                  className="hero-content-footer wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <Link to="/services" className="btn-default">
                    Our Services
                  </Link>
                  <Link to="/contact" className="btn-default btn-border">
                    Contact Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="intro-video">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro-video-box">
                <div className="video-image"> 
               <img src="images/video-bg.jpg" alt="" />
                  <div className="video-play-button">
                  <a
                    href="https://www.youtube.com/watch?v=Y-x0efG1seA"
                    className="popup-video"
                  >
                    <img src="images/play.svg" alt="" /> 
                    </a>
                   
                  
                </div>

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}





      <div className="why-choose-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp">Why Choose Us</h3>
                <h2 className="text-anime">Providing Solar Energy Solutions</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="why-choose-item wow fadeInUp"
                data-wow-delay="0.25s"
              >
              <div className="why-choose-image">
                 
                </div>

                <div className="why-choose-content">
                  <div className="why-choose-icon">
                    <img src="images/icon-whyus-1.svg" alt="" />
                  </div>

                  <h3>Efficiency & Power</h3>
                  <p>
                  Get the most out of your investment.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="why-choose-item wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="why-choose-image">
                </div>

                <div className="why-choose-content">
                  <div className="why-choose-icon">
                    <img src="images/icon-whyus-2.svg" alt="" />
                  </div>

                  <h3>Trust & Warranty</h3>
                  <p>Peace of mind with reliable service and guaranteed results.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="why-choose-item wow fadeInUp"
                data-wow-delay="0.75s"
              >
                <div className="why-choose-image">
                </div>

                <div className="why-choose-content">
                  <div className="why-choose-icon">
                    <img src="images/icon-whyus-3.svg" alt="" />
                  </div>

                  <h3>High Quality Work</h3>
                  <p>
                  Experience that delivers lasting value.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="why-choose-item wow fadeInUp"
                data-wow-delay="1.0s"
              >
                <div className="why-choose-image">
                </div>

                <div className="why-choose-content">
                  <div className="why-choose-icon">
                    <img src="images/icon-whyus-4.svg" alt="" />
                  </div>

                  <h3>24*7 Support</h3>
                  <p>
                  We're always here for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>






      <div className="infobar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="cta-box">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <div className="cta-image">
                      <figure className="image-anime">
                        <img src="images/cta-image.jpg" alt="" />
                      </figure>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="cta-content">
                      <div className="phone-icon">
                        <figure>
                          <img src="images/icon-cta-phone.svg" alt="" />
                        </figure>
                      </div>
                      <h3 className="text-anime">
                        Have Questions? <span>Call Us</span>+91 70695 17569
                      </h3>
                      <p className="wow fadeInUp" data-wow-delay="0.25s">
                      Call us today and discover how solar can save you money,
                      reduce your carbon footprint, and power your home with clean energy. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


     



      
      
            </>
        );
    }
    export default Home;