import { Link } from "react-router-dom";

const Footer=()=>{
    return (
        <>
        
      <div className="footer-ticker">
        <div className="scrolling-ticker">
          <div className="scrolling-ticker-box">
            <div className="scrolling-content">
              <span>Generate Your Own Power</span>
              <span>Reap the Returns</span>
              <span>Heal the World</span>
              <span>Efficiency & Power</span>
              <span>24*7 Support</span>
            </div>

            <div className="scrolling-content">
              <span>Generate Your Own Power</span>
              <span>Reap the Returns</span>
              <span>Heal the World</span>
              <span>Efficiency & Power</span>
              <span>24*7 Support</span>
            </div>
          </div>
        </div>
      </div>
           <footer className="main-footer">
        <div className="footer-contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="footer-contact-box wow fadeInUp"
                  data-wow-delay="0.25s"
                >
                  <div className="contact-icon-box">
                    <img src="images/icon-email.svg" alt="" />
                  </div>

                  <div className="footer-contact-info">
                    <h3>Support & Email</h3>
                    <p>sunsparkleenergy@gmail.com</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="footer-contact-box wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="contact-icon-box">
                    <img src="images/icon-phone.svg" alt="" />
                  </div>

                  <div className="footer-contact-info">
                    <h3>Customer Support</h3>
                    <p>+91 70695 17569</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="footer-contact-box wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <div className="contact-icon-box">
                    <img src="images/icon-location.svg" alt="" />
                  </div>

                  <div className="footer-contact-info">
                    <h3>Our Location</h3>
                    <p>5th floor,Akshar Arcade,near Bapasitaram chowk, Morbi, India 363641</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mega-footer">
                <div className="row">
                  <div className="col-lg-3 col-md-12">
                    <div className="footer-about">
                      <figure>
                        <img src="images/footer-logo.svg" alt="" />
                      </figure>
                      <p>
                      We Believe That Solar Energy is the Future,
                      and We Are Dedicated to Designing and Delivering Cutting-Edge Products
                      That Optimize the Efficiency and Output of Solar Energy Systems.
                      </p>
                    </div>
                    <div className="footer-social-links">
                    <ul>
                   <li>
                    <Link to="https://x.com/sunsparklenergy">
                       <i className="fa-brands fa-twitter"></i>
                     </Link>
                   </li>
                   <li>
                     <Link to="https://www.linkedin.com/in/umang-keraliya-8857a2274/">
                       <i className="fa-brands fa-linkedin-in"></i>
                     </Link>
                   </li>
                   <li>
                     <Link to="https://www.instagram.com/sunsparkle.energy/">
                       <i className="fa-brands fa-instagram"></i>
                     </Link>
                   </li>
                 </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="footer-links">
                      <h2>Quick Links</h2>
                      <ul>
                        <li>
                          <Link to="/home">Home</Link>
                        </li>
                        <li>
                        <Link to="/about">About</Link>
                        </li>
                        <li>
                        <Link to="/services">Services</Link>
                        </li>  
                        <li>
                        <Link to="/contact">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="footer-links">
                      <h2>Services</h2>
                      <ul>
                        <li>
                          <Link to="/services">Consultancy</Link>
                        </li>
                        <li>
                          <Link to="/services">Solar System</Link>
                        </li>
                        <li>
                          <Link to="/services">Solar Panel</Link>
                        </li>
                        <li>
                          <Link to="/services">BOS Material</Link>
                        </li>
                        <li>
                          <Link to="/services">ACDB/DCDB And Earthing Kit</Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="footer-links">
                      <h2>Useful Links</h2>
                      <ul>
                        <li>
                          <Link to="/privacypolicy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/faqs">FAQs</Link>
                        </li>
                        <li>
                          <Link to="/contact">Support</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-copyright">
                <div className="row">
                  <div className="col-md-12">
                    <div className="footer-copyright-text">
                      <p>Copyright © 2024 Sunsparkle Energy. All rights reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
        </>
    );
}
export default Footer;