import { Link } from "react-router-dom";

const PrivacyPolicy=()=>{
    return(
        <>    
        <div className="page-header parallaxie">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-header-box">
						<h1 className="text-anime">Privacy Policy</h1>
						<nav className="wow fadeInUp" data-wow-delay="0.25s">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
								<li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>



    <div className="container privacy-policy">
      
      <section className="content">
        <h3 className="section-title">Effective Date: 04/06/2024</h3>
        <p className="paragraph">
          SunSparkle Energy operates sunsparkleenergy.com
          . This page informs you of our policies regarding the
          collection, use, and disclosure of personal data when you use our
          Website and the choices you have associated with that data.
        </p>

        <h3 className="section-title">Introduction</h3>
        <p className="paragraph">
          This Privacy Policy describes how SunSparkle Energy collects, uses,
          and discloses your personal information when you use our website and
          related services. It also explains the choices you have associated
          with your data and how you can contact us for more information.
        </p>

        <h3 className="section-title">Data Collection</h3>
        <p className="paragraph">
          We collect several different types of information for various purposes
          to improve our service to you.
        </p>
        <ul className="list">
          <li>
            <b>Personal Data</b>
          </li>
          <p>
            While using our Website, we may ask you to provide certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul className="inner-list">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Address (if necessary for specific services)</li>
          </ul>
          <li>
            <b>Usage Data</b>
          </li>
          <p>
            We may also collect information about how you access and use the
            Website ("Usage Data"). This Usage Data may include:
          </p>
          <ul className="inner-list">
            <li>Your IP address</li>
            <li>Browser type</li>
            <li>Operating system</li>
            <li>Referral source</li>
            <li>Length of visit</li>
            <li>Pages viewed</li>
          </ul>
        </ul>

        <h3 className="section-title">Tracking Technologies and Cookies</h3>
        <p className="paragraph">
          We may use cookies and other tracking technologies to track the
          activity on our Website and hold certain information. Cookies are
          files with a small amount of data which may include an anonymous
          unique identifier. Cookies are sent to your browser from a website
          and stored on your device. Tracking technologies are also used like
          beacons, scripts, and tags to collect and track information and to
          improve and analyze our Website.
        </p>
        <p className="paragraph">
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Website.
        </p>

        <h3 className="section-title">Use of Data</h3>
        <p className="paragraph">
          SunSparkle Energy uses the collected data for various purposes:
        </p>
        <ul className="list">
          <li>To provide and maintain the Website</li>
          <li>To improve the Website</li>
          <li>To personalize your experience</li>
          <li>To process your requests and inquiries</li>
          <li>To send you promotional materials and updates (with your consent)</li>
          <li>To analyze how you use the Website</li>
          <li>To find and prevent fraud</li>
        </ul>

        {/* Add more sections as needed (e.g., Data Retention, Transfer of Data, Security, Children's Privacy, Changes to This Privacy Policy, Contact Us) */}
      </section>
    </div>
      

        </>
    );
}
export default PrivacyPolicy;




// .privacy-policy {
//     font-family: Arial, sans-serif;
//     padding: 2rem;
//     max-width: 800px;
//     margin: 0 auto;
//   }
  
// .header {
//     text-align: center;
//   }
  
// .title {
//     font-size: 2rem;
//     font-weight: bold;
//     margin-bottom: 1rem;
//   }
  
// .subtitle {
//     font-size: 1.5rem;
//     margin-top: 0;
//   }
  
// .section-title {
//     font-size: 1.2rem;
//     font-weight: bold;
//     margin-bottom: 1rem;
//   }
  
// .paragraph {
//     font-size: 1rem;
//     line-height: 1.5;
//     margin-bottom: 1rem;
//   }