import { Link } from "react-router-dom";
import React, { useState } from 'react';

const Services=()=>{



  const [formData, setFormData] = useState({
    category: '',
    name: '',
    email: '',
    phone: '',
    bill: '',
    capacity: ''
  });

  const [result, setResult] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateSavings(formData);
  };

  const calculateSavings = (data) => {
    const averageElectricityRate = 0.12; // Example rate per kWh
    const monthlySavings = data.bill * averageElectricityRate * data.capacity;
    const annualSavings = monthlySavings * 12;
    const paybackPeriod = (data.capacity * 1000) / annualSavings;

    setResult({
      savings: `$${annualSavings.toFixed(2)}`,
      paybackPeriod: paybackPeriod.toFixed(2),
    });
  };




    return(
        <>    
        <div className="page-header parallaxie">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-header-box">
						<h1 className="text-anime">Services</h1>
						<nav className="wow fadeInUp" data-wow-delay="0.25s">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
								<li className="breadcrumb-item active" aria-current="page">Services</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
{/* 
                  <div className="our-services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp">Our Services</h3>
                <h2 className="text-anime">Best Offer For Renewable Energy</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="services-slider">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="service-item">
                        <div className="service-image">
                          <figure>
                            <img src="images/service-1.jpg" alt="" />
                          </figure>

                          <div className="service-icon">
                            <img src="images/icon-service-1.svg" alt="" />
                          </div>
                        </div>

                        <div className="service-content">
                          <h3>Solar Maintenance</h3>
                          <p>
                            Aenean mattis mauris turpis, quis porta magna
                            aliquam eu. Nulla consectetur.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="service-item">
                        <div className="service-image">
                          <figure>
                            <img src="images/service-2.jpg" alt="" />
                          </figure>

                          <div className="service-icon">
                            <img src="images/icon-service-2.svg" alt="" />
                          </div>
                        </div>

                        <div className="service-content">
                          <h3>Energy Saving Devices</h3>
                          <p>
                            Aenean mattis mauris turpis, quis porta magna
                            aliquam eu. Nulla consectetur.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="service-item">
                        <div className="service-image">
                          <figure>
                            <img src="images/service-3.jpg" alt="" />
                          </figure>

                          <div className="service-icon">
                            <img src="images/icon-service-3.svg" alt="" />
                          </div>
                        </div>

                        <div className="service-content">
                          <h3>Solar Solutions</h3>
                          <p>
                            Aenean mattis mauris turpis, quis porta magna
                            aliquam eu. Nulla consectetur.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="service-item">
                        <div className="service-image">
                          <figure>
                            <img src="images/service-2.jpg" alt="" />
                          </figure>

                          <div className="service-icon">
                            <img src="images/icon-service-2.svg" alt="" />
                          </div>
                        </div>

                        <div className="service-content">
                          <h3>Energy Saving Devices</h3>
                          <p>
                            Aenean mattis mauris turpis, quis porta magna
                            aliquam eu. Nulla consectetur.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

	<div className="page-services">
		<div className="container">
			<div className="row">


      <div className="col-lg-4 col-md-6">
					<div className="service-item wow fadeInUp" data-wow-delay="1.5s">
						{/* <a href="#" className="service-box-link"></a> */}

						<div className="service-image">
							<figure>
								<img className="main-img" src="images/acdb-dcdb-solar-box.jpg" alt=""/>
							</figure>

							<div className="service-icon">
								<img src="images/icon-service-6.svg" alt=""/>
							</div>
						</div>

						<div className="service-content">
							<h3>Solar ACDB/DCDB and Earhting kit</h3>
							<p>We are one of the leading provider and Manufacturar of Solar ACDB and DCDB and earthing kit in India</p>
						</div>
					</div>
				</div>



        <div className="col-lg-4 col-md-6">
					<div className="service-item wow fadeInUp" data-wow-delay="1.25s">
						{/* <a href="#" className="service-box-link"></a> */}

						<div className="service-image">
							<figure>
								<img className="main-img" src="images/BOS.jpg" alt=""/>
							</figure>

							<div className="service-icon">
								<img src="images/icon-service-5.svg" alt=""/>
							</div>
						</div>

						<div className="service-content">
							<h3>BOS Materials</h3>
							<p>Supply and integration of high-quality Balance of System (BOS) materials to ensure maximum system efficiency.</p>
						</div>
					</div>
				</div>



        <div className="col-lg-4 col-md-6">
					<div className="service-item wow fadeInUp" data-wow-delay="0.5s">
						{/* <a href="#" className="service-box-link"></a> */}

						<div className="service-image">
							<figure>
								<img className="main-img" src="images/resident.jpg" alt=""/>
							</figure>

							<div className="service-icon">
								<img src="images/icon-service-2.svg" alt=""/>
							</div>
						</div>

						<div className="service-content">
							<h3>Residential Projects</h3>
							<p> Customized solar panel systems for homes, helping you reduce energy bills and your carbon footprint.</p>
						</div>
					</div>
				</div>




				<div className="col-lg-4 col-md-6">
					<div className="service-item wow fadeInUp" data-wow-delay="0.25s">
						{/* <a href="#" className="service-box-link"></a> */}

						<div className="service-image">
							<figure>
								<img className="main-img" src="images/solar-maintainance.jpg" alt=""/>
							</figure>

							<div className="service-icon">
								<img src="images/icon-service-1.svg" alt=""/>
							</div>
						</div>

						<div className="service-content">
							<h3>Solar Maintenance</h3>
							<p> Comprehensive maintenance services to ensure optimal performance and longevity of your solar energy systems.</p>
						</div>
					</div>
				</div>

				
				<div className="col-lg-4 col-md-6">
					<div className="service-item wow fadeInUp" data-wow-delay="0.75s">
						{/* <a href="#" className="service-box-link"></a> */}

						<div className="service-image">
							<figure>
								<img className="main-img" src="images/site-survey-for-installation.jpg" alt=""/>
							</figure>

							<div className="service-icon">
								<img src="images/icon-service-3.svg" alt=""/>
							</div>
						</div>

						<div className="service-content">
							<h3>Site Visit and Survey</h3>
							<p>Professional site visits and surveys to evaluate the feasibility and optimal design for your solar energy project.</p>
						</div>
					</div>
				</div>

				<div className="col-lg-4 col-md-6">
					<div className="service-item wow fadeInUp" data-wow-delay="1.0s">
						{/* <a href="#" className="service-box-link"></a> */}

						<div className="service-image">
							<figure>
								<img className="main-img" src="images/industrial.jpg" alt=""/>
							</figure>

							<div className="service-icon">
								<img src="images/icon-service-4.svg" alt=""/>
							</div>
						</div>

						<div className="service-content">
							<h3>Industrial Projects </h3>
							<p>Tailored solar energy solutions for industrial applications, managing everything from planning to installation and maintenance.</p>
						</div>
					</div>
				</div>

				
				
			</div>
		</div>
	</div>


      
      <div className="solar-calculator">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="calculator-box wow fadeInUp">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="section-title">
                      <h3>Solar Calculator</h3>
                      <h2>Your Solar Savings Calculator</h2>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="solar-form">
                      <form
                        id="solarForm"
                        onSubmit={handleSubmit}
                        data-toggle="validator"
                      >
                        <div className="row">
                          <div className="form-group col-md-6 mb-3">
                            <select
                              name="category"
                              className="form-control"
                              id="category"
                              value={formData.category}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Category</option>
                              <option>Residential</option>
                              <option>Commercial</option>
                            </select>
                            <div className="help-block with-errors"></div>
                          </div>

                          <div className="form-group col-md-6 mb-3">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              id="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="Full Name"
                              required
                            />
                            <div className="help-block with-errors"></div>
                          </div>

                          <div className="form-group col-md-6 mb-3">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              id="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="Email"
                              required
                            />
                            <div className="help-block with-errors"></div>
                          </div>

                          <div className="form-group col-md-6 mb-3">
                            <input
                              type="text"
                              name="phone"
                              className="form-control"
                              id="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              placeholder="Phone"
                              required
                            />
                            <div className="help-block with-errors"></div>
                          </div>

                          <div className="form-group col-md-6 mb-3">
                            <input
                              type="text"
                              name="bill"
                              className="form-control"
                              id="bill"
                              value={formData.bill}
                              onChange={handleChange}
                              placeholder="our Average Monthly Bill?"
                              required
                            />
                            <div className="help-block with-errors"></div>
                          </div>

                          <div className="form-group col-md-6 mb-3">
                            <input
                              type="number"
                              name="capacity"
                              className="form-control"
                              id="capacity"
                              value={formData.capacity}
                              onChange={handleChange}
                              placeholder="Required Solar Plant Capacity (in kW)"
                              required
                            />
                            <div className="help-block with-errors"></div>
                          </div>

                          <div className="col-md-12">
                            <button type="submit" className="btn-default">
                              Calculate
                            </button>
                            <div
                              id="msgSubmit"
                              className={`h3 text-left ${result ? '' : 'hidden'}`}
                            >
                                {result && (
                              <>
                                <h3>Estimated Savings: {result.savings}</h3>
                                <p>Payback Period: {result.paybackPeriod} years</p>
                              </>
                            )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        </>
    );
}
export default Services;