import { Link } from "react-router-dom";

const Error=()=>{
    return(
        <>    
        <div className="page-header parallaxie">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-header-box">
						<h1 className="text-anime">Page Not Found!</h1>
						<nav className="wow fadeInUp" data-wow-delay="0.25s">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
								<li className="breadcrumb-item active" aria-current="page">Page Not Found!</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="page-404">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="page-not-found-box">
						<div class="not-found-image wow fadeInUp">
							<img src="images/404-img.svg" alt=""/>
						</div>

						<h2 class="text-anime">Oops! Sorry, we could not find the Page</h2>
						<Link to="/" class="btn-default wow fadeInUp" data-wow-delay="0.5s">Back To Home</Link>
					</div>
				</div>
			</div>
		</div>
	</div>

      

        </>
    );
}
export default Error;