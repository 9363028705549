import { Link } from "react-router-dom";

const About=()=>{
    return(
        <>    
        <div className="page-header parallaxie">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-header-box">
						<h1 className="text-anime">About us</h1>
						<nav className="wow fadeInUp" data-wow-delay="0.25s">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
								<li className="breadcrumb-item active" aria-current="page">About us</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>

            <div className="about-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-image">
                
                
                <img src="images/solar5.jpg" alt="" />
              
              </div>
            </div>

            <div className="col-lg-6">
              <div className="section-title">
                <h3 className="wow fadeInUp">About Us</h3>
                <h2 className="text-anime">About Green Energy Solar</h2>
              </div>

              <div
                className="about-content wow fadeInUp"
                data-wow-delay="0.25s"
              >
                <p>
                Welcome to Sunsparkle, your trusted partner in harnessing the power of the sun. 
                We specialize in providing top-quality solar panels and comprehensive services to meet all your solar energy needs. 
                Our expertise extends beyond solar panels to include all Balance of System (BOS) components, ensuring a seamless and efficient energy solution.
                </p>

                <p>
                
                Our commitment is to deliver innovative, sustainable, and cost-effective solar energy solutions that empower our customers and contribute to a greener future.
                Join us in making the world a brighter place, one solar panel at a time.
                </p>

                <ul>
                  <li>Custom Solar Panel Design</li>
                  <li>Energy Monitoring and Management</li>
                  <li>Maintenance and Repair Services</li>
                  <li>24 X 7 Call & Chat Support</li>
                </ul>

                <Link to="/contact" className="btn-default">
                  Contact Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="vision-mission" style={{ padding: "50px 0", background: "#f9f9f9" }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="vision-image">
                            <figure>
                                <img src="images/mission_vision.png" alt="MissionVision" style={{ width: "100%", borderRadius: "8px" }} />
                            </figure>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="vision-content">
                            <h2 style={{ fontWeight: "bold", color: "#333" }}>Our Vision</h2>
                            <p style={{ fontSize: "16px", color: "#666", lineHeight: "1.6" }}>At Sunsparkle, our vision is to lead the way in renewable energy solutions, making solar power accessible and affordable for everyone. We aim to create a sustainable future where clean energy powers homes and businesses, reducing our carbon footprint and preserving the environment for future generations.</p>
                            <h2 style={{ fontWeight: "bold", color: "#333", marginTop: "30px" }}>Our Mission</h2>
                            <p style={{ fontSize: "16px", color: "#666", lineHeight: "1.6" }}>Our mission is to provide innovative solar energy solutions that meet the diverse needs of our customers. We are committed to delivering high-quality products, exceptional service, and continuous support to ensure the success of every solar project we undertake. Together, we can create a brighter, cleaner, and more sustainable world.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




      <div className="stat-counter">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <img src="images/icon-project.svg" alt="" />
                </div>

                <div className="counter-content">
                  <h3>
                    <span className="counter">1000</span>+
                  </h3>
                  <p>Project Done</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <img src="images/icon-happy-clients.svg" alt="" />
                </div>

                <div className="counter-content">
                  <h3>
                    <span className="counter">1200</span>+
                  </h3>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <img src="images/icon-award.svg" alt="" />
                </div>

                <div className="counter-content">
                  <h3>
                    <span className="counter">850</span>+
                  </h3>
                  <p>Award Winning</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <img src="images/icon-ratting.svg" alt="" />
                </div>

                <div className="counter-content">
                  <h3>
                    <span className="counter">1100</span>+
                  </h3>
                  <p>Rating Customer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>









        <div className="our-process">
         <div className="container">
           <div className="row">
             <div className="col-md-12">
               <div className="section-title">
                 <h3 className="wow fadeInUp">Our Latest Process</h3>
                 <h2 className="text-anime">Our Work Process</h2>
           </div>
         </div>
          </div>

           <div className="row">
             <div className="col-md-4">
               <div
                className="step-item step-1 wow fadeInUp"
                data-wow-delay="0.25s"
              >
                <div className="step-header">
                  <div className="step-icon">
                    <figure>
                      <img src="images/icon-step-1.svg" alt="" />
                    </figure>
                    <span className="step-no">01</span>
                  </div>
                </div>

                <div className="step-content">
                  <h3>Project Planing</h3>
                  <p>
                  We meticulously design and strategize each solar project to meet your specific energy needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="step-item step-2 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="step-header">
                  <div className="step-icon">
                    <figure>
                      <img src="images/icon-step-2.svg" alt="" />
                    </figure>
                    <span className="step-no">02</span>
                  </div>
                </div>

                <div className="step-content">
                  <h3>Research & Analysis</h3>
                  <p>
                  Our experts conduct thorough research and analysis to ensure optimal solar solutions.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="step-item wow fadeInUp" data-wow-delay="0.75s">
                <div className="step-header">
                  <div className="step-icon">
                    <figure>
                      <img src="images/icon-step-3.svg" alt="" />
                    </figure>
                    <span className="step-no">03</span>
                  </div>
                </div>

                <div className="step-content">
                  <h3>Solar Installation</h3>
                  <p>
                  Our skilled technicians install solar systems efficiently, ensuring seamless integration with your property.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      
      



      {/* <div className="our-skills">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <h3 className="wow fadeInUp">Energy Progress</h3>
                <h2 className="text-anime">
                  Best Solution For Your Solar Energy
                </h2>
                <p className="wow fadeInUp" data-wow-delay="0.25s">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="skills-box">
                <div className="skillbar" data-percent="95%">
                  <div className="skill-data">
                    <div className="title">Solar Panels</div>
                    <div className="count">95%</div>
                  </div>
                  <div className="skill-progress">
                    <div className="count-bar"></div>
                  </div>
                </div>

                <div className="skillbar" data-percent="80%">
                  <div className="skill-data">
                    <div className="title">Hybrid Energy</div>
                    <div className="count">80%</div>
                  </div>
                  <div className="skill-progress">
                    <div className="count-bar"></div>
                  </div>
                </div>

                <div className="skillbar" data-percent="70%">
                  <div className="skill-data">
                    <div className="title">Marketing</div>
                    <div className="count">70%</div>
                  </div>
                  <div className="skill-progress">
                    <div className="count-bar"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      

        </>
    );
}
export default About;