// import { Link } from "react-router-dom";

// const Contact=()=>{
//     return(
//         <>
// <div className="page-header parallaxie">
// 		<div className="container">
// 			<div className="row">
// 				<div className="col-md-12">
// 					<div className="page-header-box">
// 						<h1 className="text-anime">Contact us</h1>
// 						<nav className="wow fadeInUp" data-wow-delay="0.25s">
// 							<ol className="breadcrumb">
// 								<li className="breadcrumb-item"><Link to="/" >Home</Link></li>
// 								<li className="breadcrumb-item active" aria-current="page">Contact us</li>
// 							</ol>
// 						</nav>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	</div>



// 	<div className="contact-information">
// 		<div className="container">
// 			<div className="row">
// 				<div className="col-md-12">
// 					<div className="section-title">
// 						<h3 className="wow fadeInUp">Contact Details</h3>
// 						<h2 className="text-anime">Happy to Answer All Your Questions</h2>
// 					</div>
// 				</div>
// 			</div>
// 			<div className="row">
// 				<div className="col-lg-3 col-md-6">
// 					<div className="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
// 						<div className="contact-image">
// 							<figure className="image-anime">
// 								<img src="images/location-img.jpg" alt=""/>
// 							</figure>
// 						</div>

// 						<div className="contact-info-content">
// 							<h3>Our Address:</h3>
// 							<p>Ravapar Rd.,Morbi,India</p>
// 						</div>

// 						<div className="contact-icon">
// 							<img src="images/icon-location.svg" alt=""/>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="col-lg-3 col-md-6">
// 					<div className="contact-info-item wow fadeInUp" data-wow-delay="0.5s">
// 						<div className="contact-image">
// 							<figure className="image-anime">
// 								<img src="images/email-img.jpg" alt=""/>
// 							</figure>
// 						</div>

// 						<div className="contact-info-content">
// 							<h3>Email:</h3>
// 							<p>sunsparkleenergy@gmail.com</p>
// 						</div>

// 						<div className="contact-icon">
// 							<img src="images/icon-mail.svg" alt=""/>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="col-lg-3 col-md-6">
// 					<div className="contact-info-item wow fadeInUp" data-wow-delay="0.75s">
// 						<div className="contact-image">
// 							<figure className="image-anime">
// 								<img src="images/phone-img.jpg" alt=""/>
// 							</figure>
// 						</div>

// 						<div className="contact-info-content">
// 							<h3>Phones:</h3>
// 							<p>(+91) 70695 17569<br/> (+91) 87581 36678</p>
// 						</div>

// 						<div className="contact-icon">
// 							<img src="images/icon-phone.svg" alt=""/>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="col-lg-3 col-md-6">
// 					<div className="contact-info-item wow fadeInUp" data-wow-delay="1.0s">
// 						<div className="contact-image">
// 							<figure className="image-anime">
// 								<img className="main-img" src="images/followus.jpg" alt=""/>
// 							</figure>
// 						</div>

// 						<div className="contact-info-content">
// 							<h3>Follow Us:</h3>
							
// <ul>
//                    <li>
//                     <Link to="https://x.com/sunsparklenergy">
//                        <i className="fa-brands fa-twitter"></i>
//                      </Link>
//                    </li>
//                    <li>
//                      <Link to="https://www.linkedin.com/in/umang-keraliya-8857a2274/">
//                        <i className="fa-brands fa-linkedin-in"></i>
//                      </Link>
//                    </li>
//                    <li>
//                      <Link to="https://www.instagram.com/sunsparkle.energy/">
//                        <i className="fa-brands fa-instagram"></i>
//                      </Link>
//                    </li>
//                  </ul>
// 						</div>

// 						<div className="contact-icon">
// 							<img src="images/icon-follow.svg" alt=""/>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	</div>

// 	<div className="google-map-form">
// 		<div className="google-map">
// 			<iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d56481.31329163797!2d-82.30112043759952!3d27.776444959332093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sUnited%20States%20solar!5e0!3m2!1sen!2sin!4v1706008331370!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
// 		</div>

// 		<div className="container">
// 			<div className="row">
// 				<div className="col-lg-6 offset-lg-6">
// 					<div className="contact-form-box">
// 						<div className="section-title">
// 							<h3 className="wow fadeInUp">Contact Now</h3>
// 							<h2 className="text-anime">Get In Touch With Us</h2>
// 						</div>
// 						<div className="contact-form wow fadeInUp" data-wow-delay="0.75s">
// 							<form id="contactForm" action="#" method="POST" data-toggle="validator">
// 								<div className="row">
// 									<div className="form-group col-md-6 mb-4">
// 										<input type="text" name="name" className="form-control" id="name" placeholder="Name" required />
// 										<div className="help-block with-errors"></div>
// 									</div>

// 									<div className="form-group col-md-6 mb-4">
// 										<input type="email" name ="email" className="form-control" id="email" placeholder="Email" required />
// 										<div className="help-block with-errors"></div>
// 									</div>

// 									<div className="form-group col-md-6 mb-4">
// 										<input type="text" name="phone" className="form-control" id="phone" placeholder="Phone" required />
// 										<div className="help-block with-errors"></div>
// 									</div>

// 									<div className="form-group col-md-6 mb-4">
// 										<input type="text" name="subject" className="form-control" id="subject" placeholder="Subject" required />
// 										<div className="help-block with-errors"></div>
// 									</div>

// 									<div className="form-group col-md-12 mb-4">
// 										<textarea name="msg" className="form-control" id="msg" rows="4" placeholder="Write a Message" required></textarea>
// 										<div className="help-block with-errors"></div>
// 									</div>

// 									<div className="col-md-12 text-center">
// 										<button type="submit" className="btn-default">Submit Now</button>
// 										<div id="msgSubmit" className="h3 text-left hidden"></div>
// 									</div>
// 								</div>
// 							</form>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	</div>
//     </>);
//     }
// export default Contact;












// import { Link } from "react-router-dom";
// import emailjs from "emailjs-com";
// import { useState } from "react";

// const Contact = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     subject: "",
//     msg: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     emailjs
//       .send(
//         "service_kpve6lw",
//         "template_gtgw6uf",
//         formData,
//         "wc34ah3jlexdBoK4o"
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//           alert("Email sent successfully!");
//         },
//         (error) => {
//           console.log(error.text);
//           alert("Failed to send email.");
//         }
//       );

//     setFormData({
//       name: "",
//       email: "",
//       phone: "",
//       subject: "",
//       msg: "",
//     });
//   };
//   return (
//     <>
//       <div className="page-header parallaxie">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="page-header-box">
//                 <h1 className="text-anime">Contact us</h1>
//                 <nav className="wow fadeInUp" data-wow-delay="0.25s">
//                   <ol className="breadcrumb">
//                     <li className="breadcrumb-item">
//                       <Link to="/">Home</Link>
//                     </li>
//                     <li className="breadcrumb-item active" aria-current="page">
//                       Contact us
//                     </li>
//                   </ol>
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="contact-information">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="section-title">
//                 <h3 className="wow fadeInUp">Contact Details</h3>
//                 <h2 className="text-anime">
//                   Happy to Answer All Your Questions
//                 </h2>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-lg-3 col-md-6">
//               <div
//                 className="contact-info-item wow fadeInUp"
//                 data-wow-delay="0.25s"
//               >
//                 <div className="contact-image">
//                   <figure className="image-anime">
//                     <img src="images/location-img.jpg" alt="" />
//                   </figure>
//                 </div>

//                 <div className="contact-info-content">
//                   <h3>Our Address:</h3>
//                   <p>Ravapar Rd.,Morbi,India</p>
//                 </div>

//                 <div className="contact-icon">
//                   <img src="images/icon-location.svg" alt="" />
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3 col-md-6">
//               <div
//                 className="contact-info-item wow fadeInUp"
//                 data-wow-delay="0.5s"
//               >
//                 <div className="contact-image">
//                   <figure className="image-anime">
//                     <img src="images/email-img.jpg" alt="" />
//                   </figure>
//                 </div>

//                 <div className="contact-info-content">
//                   <h3>Email:</h3>
//                   <p>sunsparkleenergy@gmail.com</p>
//                 </div>

//                 <div className="contact-icon">
//                   <img src="images/icon-mail.svg" alt="" />
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3 col-md-6">
//               <div
//                 className="contact-info-item wow fadeInUp"
//                 data-wow-delay="0.75s"
//               >
//                 <div className="contact-image">
//                   <figure className="image-anime">
//                     <img src="images/phone-img.jpg" alt="" />
//                   </figure>
//                 </div>

//                 <div className="contact-info-content">
//                   <h3>Phones:</h3>
//                   <p>
//                     (+91) 70695 17569
//                     <br /> (+91) 87581 36678
//                   </p>
//                 </div>

//                 <div className="contact-icon">
//                   <img src="images/icon-phone.svg" alt="" />
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3 col-md-6">
//               <div
//                 className="contact-info-item wow fadeInUp"
//                 data-wow-delay="1.0s"
//               >
//                 <div className="contact-image">
//                   <figure className="image-anime">
//                     <img
//                       className="main-img"
//                       src="images/followus.jpg"
//                       alt=""
//                     />
//                   </figure>
//                 </div>

//                 <div className="contact-info-content">
//                   <h3>Follow Us:</h3>

//                   <ul>
//                     <li>
//                       <Link to="https://x.com/sunsparklenergy">
//                         <i className="fa-brands fa-twitter"></i>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link to="https://www.linkedin.com/in/umang-keraliya-8857a2274/">
//                         <i className="fa-brands fa-linkedin-in"></i>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link to="https://www.instagram.com/sunsparkle.energy/">
//                         <i className="fa-brands fa-instagram"></i>
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>

//                 <div className="contact-icon">
//                   <img src="images/icon-follow.svg" alt="" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="google-map-form">
//         <div className="google-map">
//           <iframe
//             src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d56481.31329163797!2d-82.30112043759952!3d27.776444959332093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sUnited%20States%20solar!5e0!3m2!1sen!2sin!4v1706008331370!5m2!1sen!2sin"
//             width="600"
//             height="450"
//             style={{ border: 0 }}
//             allowFullScreen=""
//             loading="lazy"
//             referrerPolicy="no-referrer-when-downgrade"
//           ></iframe>
//         </div>

//         <div className="container">
//           <div className="row">
//             <div className="col-lg-6 offset-lg-6">
//               <div className="contact-form-box">
//                 <div className="section-title">
//                   <h3 className="wow fadeInUp">Contact Now</h3>
//                   <h2 className="text-anime">Get In Touch With Us</h2>
//                 </div>
//                 <div
//                   className="contact-form wow fadeInUp"
//                   data-wow-delay="0.75s"
//                 >
//                   <form
//                     id="contactForm"
//                     onSubmit={handleSubmit}
//                     data-toggle="validator"
//                   >
//                     <div className="row">
//                       <div className="form-group col-md-6 mb-4">
//                         <input
//                           type="text"
//                           name="name"
//                           className="form-control"
//                           id="name"
//                           placeholder="Name"
//                           value={formData.name}
//                           onChange={handleChange}
//                           required
//                         />
//                         <div className="help-block with-errors"></div>
//                       </div>

//                       <div className="form-group col-md-6 mb-4">
//                         <input
//                           type="email"
//                           name="email"
//                           className="form-control"
//                           id="email"
//                           placeholder="Email"
//                           value={formData.email}
//                           onChange={handleChange}
//                           required
//                         />
//                         <div className="help-block with-errors"></div>
//                       </div>

//                       <div className="form-group col-md-6 mb-4">
//                         <input
//                           type="text"
//                           name="phone"
//                           className="form-control"
//                           id="phone"
//                           placeholder="Phone"
//                           value={formData.phone}
//                           onChange={handleChange}
//                           required
//                         />
//                         <div className="help-block with-errors"></div>
//                       </div>

//                       <div className="form-group col-md-6 mb-4">
//                         <input
//                           type="text"
//                           name="subject"
//                           className="form-control"
//                           id="subject"
//                           placeholder="Subject"
//                           value={formData.subject}
//                           onChange={handleChange}
//                           required
//                         />
//                         <div className="help-block with-errors"></div>
//                       </div>

//                       <div className="form-group col-md-12 mb-4">
//                         <textarea
//                           name="msg"
//                           className="form-control"
//                           id="msg"
//                           rows="4"
//                           placeholder="Write a Message"
//                           value={formData.msg}
//                           onChange={handleChange}
//                           required
//                         ></textarea>
//                         <div className="help-block with-errors"></div>
//                       </div>

//                       <div className="col-md-12 text-center">
//                         <button type="submit" className="btn-default">
//                           Submit Now
//                         </button>
//                         <div
//                           id="msgSubmit"
//                           className="h3 text-left hidden"
//                         ></div>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Contact;










import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { useEffect, useState } from "react";
// import './Contact.css'; // Make sure to create this CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    msg: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  useEffect(() => {
    if (submissionStatus) {
      const timer = setTimeout(() => {
        setSubmissionStatus(null);
      }, 3000); // Hide message after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [submissionStatus]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_kpve6lw",
        "template_gtgw6uf",
        formData,
        "wc34ah3jlexdBoK4o"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmissionStatus('success');
        },
        (error) => {
          console.log(error.text);
          setSubmissionStatus('error');
        }
      );

    setFormData({
      name: "",
      email: "",
      phone: "",
      subject: "",
      msg: "",
    });
  };

  return (
    <>
      <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Contact us</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp">Contact Details</h3>
                <h2 className="text-anime">
                  Happy to Answer All Your Questions
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="contact-info-item wow fadeInUp"
                data-wow-delay="0.25s"
              >
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/location-img.jpg" alt="" />
                  </figure>
                </div>

                <div className="contact-info-content">
                  <h3>Our Address:</h3>
                  <p>5th floor,Akshar Arcade,near Bapasitaram chowk, Morbi, India 363641</p>
                </div>

                <div className="contact-icon">
                  <img src="images/icon-location.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="contact-info-item wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/email-img.jpg" alt="" />
                  </figure>
                </div>

                <div className="contact-info-content">
                  <h3>Email:</h3>
                  <p>sunsparkleenergy@gmail.com</p>
                </div>

                <div className="contact-icon">
                  <img src="images/icon-mail.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="contact-info-item wow fadeInUp"
                data-wow-delay="0.75s"
              >
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/phone-img.jpg" alt="" />
                  </figure>
                </div>

                <div className="contact-info-content">
                  <h3>Phones:</h3>
                  <p>
                    (+91) 70695 17569
                    <br /> (+91) 87581 36678
                  </p>
                </div>

                <div className="contact-icon">
                  <img src="images/icon-phone.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="contact-info-item wow fadeInUp"
                data-wow-delay="1.0s"
              >
                <div className="contact-image">
                  <figure className="image-anime">
                    <img
                      className="main-img"
                      src="images/followus.jpg"
                      alt=""
                    />
                  </figure>
                </div>

                <div className="contact-info-content">
                  <h3>Follow Us:</h3>

                  <ul>
                    <li>
                      <Link to="https://x.com/sunsparklenergy">
                        <i className="fa-brands fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/in/umang-keraliya-8857a2274/">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/sunsparkle.energy/">
                        <i className="fa-brands fa-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="contact-icon">
                  <img src="images/icon-follow.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="google-map-form">
        <div className="google-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.022776863315!2d70.8222035!3d22.8016199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598dd2735f5607%3A0xe5f3abbc1b4573e2!2sSunsparkle%20Energy!5e0!3m2!1sen!2sin!4v1724927689198!5m2!1sen!2sin"
		 width="600" 
		 height="450"
		 allowfullscreen=""
		 loading="lazy" 
		 referrerpolicy="no-referrer-when-downgrade"
		 title="SunSparkle Energy Location Map" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="contact-form-box">
                <div className="section-title">
                  <h3 className="wow fadeInUp">Contact Now</h3>
                  <h2 className="text-anime">Get In Touch With Us</h2>
                </div>
                <div
                  className="contact-form wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <form
                    id="contactForm"
                    onSubmit={handleSubmit}
                    data-toggle="validator"
                  >
                    <div className="row">
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>

                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>

                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          id="phone"
                          placeholder="Phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>

                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>

                      <div className="form-group col-md-12 mb-4">
                        <textarea
                          name="msg"
                          className="form-control"
                          id="msg"
                          rows="4"
                          placeholder="Write a Message"
                          value={formData.msg}
                          onChange={handleChange}
                          required
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button type="submit" className="btn-default">
                          Submit Now
                        </button>
                        {submissionStatus === 'success' && (
                          <div className="success-message">
                            Email sent successfully!
                          </div>
                        )}
                        {submissionStatus === 'error' && (
                          <div className="error-message">
                            Failed to send email.
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
