
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Error from "./components/Error";
import Faq from "./components/Faq";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
<>
<BrowserRouter>
<ScrollToTop />
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Home/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/services" element={<Services/>}></Route>
        <Route path="/booknow" element={<Contact/>}></Route>
        <Route path="/faqs" element={<Faq/>}></Route>
        
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}></Route>
        <Route path="*" element={<Error/>}></Route>
      </Route>
    </Routes>
  </BrowserRouter>


  

  </>  
   
   

    

   
  
    
  );
}

export default App;
